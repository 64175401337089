import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

function PrivateNavbar({ logout }) {
  const [isVehiclesMenuOpen, setVehiclesMenuOpen] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await fetch('/api/vehicles');
        const data = await response.json();
        setVehicles(data);
      } catch (error) {
        console.error('Errore nel recupero dei veicoli:', error);
      }
    };

    fetchVehicles();
  }, []);

  const toggleVehiclesMenu = () => {
    setVehiclesMenuOpen(!isVehiclesMenuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleVehiclesClick = () => {
    navigate('/dashboard/vehicles');
  };

  return (
    <div className="bg-gray-800 text-white shadow-md">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <img src="/logo.png" alt="Saix Logo" className="h-8 mr-2" />
        </div>
        <nav className="flex space-x-4">
          <div className="relative">
            <button
              onClick={handleVehiclesClick}
              className="py-2 px-4 rounded-md hover:bg-gray-700 focus:outline-none"
            >
              Vehicles
            </button>
          </div>
          <NavLink to="/dashboard/trailers" className="py-2 px-4 rounded-md hover:bg-gray-700">
            Trailers
          </NavLink>
          <NavLink to="/dashboard/3dvehicles" className="py-2 px-4 rounded-md hover:bg-gray-700">
            Digital Twin
          </NavLink>
          <NavLink to="/dashboard/bestchoice" className="py-2 px-4 rounded-md hover:bg-gray-700">
            Best Route Choice
          </NavLink>
          <NavLink to="/dashboard/policy" className="py-2 px-4 rounded-md hover:bg-gray-700">
            Policy Builder
          </NavLink>
          <NavLink to="/dashboard/employees" className="py-2 px-4 rounded-md hover:bg-gray-700">
            Employees
          </NavLink>
          <NavLink to="/dashboard" className="py-2 px-4 rounded-md hover:bg-gray-700">
            Dashboard
          </NavLink>
          <button onClick={handleLogout} className="py-2 px-4 rounded-md hover:bg-gray-700">
            Logout
          </button>
        </nav>
      </div>
    </div>
  );
}

export default PrivateNavbar;
