// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vehicle-form-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }


  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .vehicle-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .vehicle-form label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .vehicle-form input {
    width: 60%;
    padding: 5px;
  }
  
  .submit-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/VehicleForm.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;;EAGA;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".vehicle-form-container {\n    font-family: Arial, sans-serif;\n    max-width: 600px;\n    margin: 0 auto;\n    margin-top: 40px;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  }\n\n\n  h2 {\n    text-align: center;\n    color: #333;\n    margin-bottom: 20px;\n  }\n  \n  .vehicle-form {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .vehicle-form label {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .vehicle-form input {\n    width: 60%;\n    padding: 5px;\n  }\n  \n  .submit-button {\n    padding: 10px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    align-self: center;\n  }\n  \n  .submit-button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
