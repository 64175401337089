import React from 'react';
import manu from "../assets/Manu.png";
import sere from "../assets/sere.png";
import marco from "../assets/marco.png";
import albe from "../assets/beso.png";
import gigi from "../assets/gigi.jpg";
import anto from "../assets/anto.jpg";
import { Link } from 'react-router-dom';

function Team() {
    return (
        <div className="py-20 bg-gradient-to-r from-gray-100 to-gray-300">
            <div className="container mx-auto px-6 md:px-12 xl:px-32">
                <div className="mb-16 text-center">
                    <h2 className="mb-4 text-center text-3xl text-gray-900 font-extrabold md:text-5xl">TEAM</h2>
                </div>
                <div className="grid gap-12 items-center md:grid-cols-3 xl:grid-cols-3 grid-cols-2">
                    {[{ img: manu, name: "Manuel Bassoli", role: "Company administration and consulting" },
                    { img: marco, name: "Marco Melloni", role: "Digital Automation Engineering" },
                    { img: sere, name: "Serena Castaldo", role: "International Management" },
                    { img: albe, name: "Alberto Besozzi", role: "Electronics Engineering" },
                    { img: gigi, name: "Luigi Notarnicola", role: "Vehicle Engineering" },
                    { img: anto, name: "Antonio Gramegna", role: "Vehicle Engineering" }
                    ].map((member, index) => (
                        <div key={index} className="bg-white rounded-xl p-4 max-w-xs mx-auto text-center shadow-lg transition-transform transform hover:scale-105">
                            <img className="w-64 h-64 mx-auto object-cover rounded-full border-4 border-gray-200 md:w-40 md:h-40 lg:w-64 lg:h-64"
                                src={member.img} alt={member.name} loading="lazy" />
                            <div className="mt-4">
                                <h4 className="text-2xl font-semibold">{member.name}</h4>
                                <span className="block text-sm text-gray-500">{member.role}</span>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-8 md:grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 mx-auto" style={{ maxWidth: '1200px' }}>
    <div className="w-full">
        <div className="mb-16 text-center">
            <h2 className="mb-4 text-center text-3xl text-gray-900 font-extrabold md:text-5xl">ABOUT US</h2>
        </div>
        <div className="bg-white rounded-2xl p-6 text-center shadow-lg">
            <p className="text-gray-700">
                Our team is composed of people from different backgrounds.<span className="font-bold"> Manuel</span> and <span className="font-bold">Serena</span> have been studying in the economics and management field,
                so they are mainly taking care about the business side of our idea. In particular, Manuel also worked in the fleet management office of a company
                situated in Bergamo, which was really helpful in the ideation phase of our project. Then there are <span className="font-bold">Luigi</span> and <span className="font-bold">Nicola</span>, our skillful vehicle engineers who helped
                us in the analysis of data collected. Finally, there are <span className="font-bold">Alberto</span>, with a bachelor in electronic engineering, and <span className="font-bold">Marco</span>, a management engineer who is
                now following the path of Digital Automation Engineering and programming. The first one is the one who took care about all the pitches during the year, while the second one took
                care of this website and the development of the software itself.
            </p>
        </div>
    </div>
    <div className="w-full">
        <div className="mb-16 text-center">
            <h2 className="mb-4 text-center text-3xl text-gray-900 font-extrabold md:text-5xl">NEXT STEP</h2>
        </div>
        <div className="bg-white rounded-2xl p-6 text-center shadow-lg">
            <p className="text-gray-700">
                The software you are experiencing right now is just our first prototype, but the steps towards a complete system are not so distant from where we are.
                We are all united under the love for innovation and technology development, and for this reason we will continue working hard on the
                evolution of our idea, with the same effort we put during those months.
                The only thing you can do is to <span className="font-bold">BE PART OF IT NOW.</span>
            </p>
            <Link
                to="/contact"
                className="inline-flex items-center justify-center px-6 py-3 mt-4 text-sm text-white bg-blue-500 rounded-full shadow-sm sm:px-8 sm:py-4 hover:bg-blue-600 transition duration-300"
            >
                Contact Us
                <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
            </Link>
        </div>
    </div>
</div>

            </div>
        </div>
    )
}

export default Team;