import React, { useState } from 'react';
import axios from 'axios';
import './RegisterVehicle.css'; // Assuming you create a CSS file for styling

const RegisterVehicle = () => {
  const [formData, setFormData] = useState({
    nome: '',
    carico_massimo_trasportabile: '',
    fuel_tank_capacity: '',
    consumo_a_pieno_carico: '',
    consumo_a_vuoto: '',
    emissioni_dichiarate: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/api/add_vehicle', formData)
      .then(response => {
        setSuccessMessage('Vehicle added successfully!');
        setErrorMessage('');
        setFormData({
          nome: '',
          carico_massimo_trasportabile: '',
          fuel_tank_capacity: '',
          consumo_a_pieno_carico: '',
          consumo_a_vuoto: '',
          emissioni_dichiarate: '',
          model_path: ''
        });
      })
      .catch(error => {
        setErrorMessage('Error adding vehicle. Please try again.');
        setSuccessMessage('');
      });
  };

  return (
    <div className="register-vehicle-container">
      <h2>Register Vehicle</h2>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleSubmit} className="register-vehicle-form">
        <label>
          Name:
          <input type="text" name="nome" value={formData.nome} onChange={handleChange} required />
        </label>
        <label>
          Maximum Load Capacity (tonnes):
          <input type="number" name="carico_massimo_trasportabile" value={formData.carico_massimo_trasportabile} onChange={handleChange} required />
        </label>
        <label>
          Fuel Tank Capacity (litres):
          <input type="number" name="fuel_tank_capacity" value={formData.fuel_tank_capacity} onChange={handleChange} required />
        </label>
        <label>
          Fuel Consumption at Full Load (l/100km):
          <input type="number" name="consumo_a_pieno_carico" value={formData.consumo_a_pieno_carico} onChange={handleChange} required />
        </label>
        <label>
          Fuel Consumption at Empty Load (l/100km):
          <input type="number" name="consumo_a_vuoto" value={formData.consumo_a_vuoto} onChange={handleChange} required />
        </label>
        <label>
          Declared Emissions (g/km):
          <input type="number" name="emissioni_dichiarate" value={formData.emissioni_dichiarate} onChange={handleChange} required />
        </label>
        <label>
          URL 3D:
          <input type="text" name="model_path" value={formData.model_path} onChange={handleChange} required />
        </label>
        <button type="submit" className="submit-button">Register Vehicle</button>
      </form>
    </div>
  );
};

export default RegisterVehicle;
