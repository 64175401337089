import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './VehicleDetail.css';

const VehicleDetail = () => {
    const { id } = useParams();
    const [vehicle, setVehicle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`/api/vehicles/${id}`)
            .then(response => {
                setVehicle(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError('Vehicle not found');
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return <div className="spinner">Loading...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div className="container_card">
            <div className="card">
                <div className="card-header">
                    <h2>Vehicle Details</h2>
                </div>
                <div className="card-body">
                    {vehicle && (
                        <ul className="vehicle-details">
                            <li><strong>ID:</strong> {vehicle.id}</li>
                            <li><strong>Nome:</strong> {vehicle.nome}</li>
                            <li><strong>Carico Massimo Trasportabile:</strong> {vehicle.carico_massimo_trasportabile}</li>
                            <li><strong>Fuel Tank Capacity:</strong> {vehicle.fuel_tank_capacity}</li>
                            <li><strong>Consumo a Pieno Carico:</strong> {vehicle.consumo_a_pieno_carico}</li>
                            <li><strong>Consumo a Vuoto:</strong> {vehicle.consumo_a_vuoto}</li>
                            <li><strong>Emissioni Dichiarate:</strong> {vehicle.emissioni_dichiarate}</li>
                            <li><strong>Model Path:</strong> {vehicle.model_path}</li>
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VehicleDetail;
