import React from 'react';
import best from "../assets/BestChoiceSaix.png";
import data from "../assets/DBSaix.png"
import digital1 from "../assets/DigitalSaix.png"
import digital2 from "../assets/Digital2Saix.png"

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Services.css'; // Assicurati di creare questo file CSS

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};


function Services() {
  return (
    <section className="bg-white services-section">
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl">
          Explore our <br />
          <span className="text-blue-500">services</span>
        </h1>

        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-8 sm:grid-cols-2 xl:grid-cols-3">
          <div className="service-card">
            <span className="icon-bg">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
              </svg>
            </span>

            <h1 className="service-title">Optimal Logistic Organization</h1>

            <p className="service-description">
              We provide advanced logistic optimization solutions for your truck fleet, determining the best vehicle among your database in order to complete each transportation. We base our analysis on the load, the route, available trucks and the state of each of them extracted by the digital twin. We optimize your logistics to save time and money.
            </p>

            <a href="/contact" className="learn-more-link">
              <span className="mx-1">Ask for a Demo</span>
              <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </a>
          </div>
          <div className="center-container">
            <div className="container-photo">
              <Slider {...settings}>
                <div>
                  <img src={best} alt="Image 1" style={{ width: '100%' }} />
                </div>
                <div>
                  <img src={data} alt="Image 2" style={{ width: '100%' }} />
                </div>

              </Slider>
            </div>
          </div>
          <div className="service-card">
            <span className="icon-bg">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
              </svg>
            </span>

            <h1 className="service-title">Fleet Management System (FMS)</h1>

            <p className="service-description">
              Our comprehensive FMS includes a robust database for trucks and trailers, offering seamless integration with your existing logistics and telematics software.
            </p>

            <a href="/contact" className="learn-more-link">
              <span className="mx-1">Tell us about your needs</span>
              <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </a>
          </div>

          <div className="service-card">
            <span className="icon-bg">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
              </svg>
            </span>
            <h1 className="service-title">Digital Twin & Predictive Maintenance</h1>

            <p className="service-description">
              Each vehicle in your fleet has a digital twin, allowing for real-time monitoring and predictive maintenance. Ensure your fleet's optimal performance and prevent downtime with advanced analytics, and stay up to date with the most innovative technologies
            </p>

            <a href="/contact" className="learn-more-link">
              <span className="mx-1">Check the platform</span>
              <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </a>
          </div>
          <div className="center-container">
            <div className="container-photo">
              <Slider {...settings}>

                <div>
                  <img src={digital1} alt="Image 1" style={{ width: '100%' }} />
                </div>
                <div>
                  <img src={digital2} alt="Image 2" style={{ width: '100%' }} />
                </div>
              </Slider>
            </div>
          </div>
          <div className="service-card">
            <span className="icon-bg">
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.7766 3.25931C17.1453 1.58023 14.5005 1.58023 12.8692 3.25931L8.4387 7.81967C6.80744 9.49875 6.80744 12.2211 8.4387 13.9002C10.07 15.5792 12.7148 15.5792 14.346 13.9002L14.9649 13.2632C14.7862 12.943 14.5719 12.6127 14.346 12.3801C14.12 12.1475 13.7991 11.927 13.4881 11.743L12.8692 12.38C12.0536 13.2196 10.7312 13.2196 9.91554 12.38C9.09991 11.5405 9.09991 10.1793 9.91554 9.33979L14.346 4.77943C15.1617 3.93989 16.4841 3.93989 17.2997 4.77943C18.1153 5.61897 18.1153 6.98013 17.2997 7.81967L16.4477 8.69668C16.7486 8.87596 17.0619 9.09519 17.2996 9.33987C17.5561 9.60384 17.768 9.91363 17.9354 10.2056L18.7766 9.33979C20.4078 7.66071 20.4078 4.93839 18.7766 3.25931Z" fill="currentColor" />
                <path d="M16.5613 10.0998C14.93 8.42077 12.2852 8.42077 10.654 10.0998L10.0371 10.7348C10.2176 11.0587 10.4324 11.3919 10.654 11.62C10.9104 11.8839 11.2114 12.1021 11.495 12.2744L12.1308 11.62C12.9464 10.7804 14.2688 10.7804 15.0845 11.62C15.9001 12.4595 15.9001 13.8207 15.0845 14.6602L10.654 19.2206C9.83832 20.0601 8.51592 20.0601 7.70029 19.2206C6.88465 18.381 6.88465 17.0199 7.70029 16.1803L8.54137 15.3146C8.25772 15.1423 7.95674 14.9242 7.70029 14.6602C7.44384 14.3962 7.23192 14.0864 7.06453 13.7945L6.22345 14.6602C4.59218 16.3393 4.59218 19.0616 6.22345 20.7407C7.85472 22.4198 10.4995 22.4198 12.1308 20.7407L16.5613 16.1803C18.1926 14.5013 18.1926 11.7789 16.5613 10.0998Z" fill="currentColor" />
              </svg>

            </span>

            <h1 className="service-title">Service Interaction</h1>

            <p className="service-description">
              All our services are connected one to the other. The Digital Twin that you can see here uses the informations taken from the database in order to offer a better experience to the user. The style and the design are really simple and comprehensive
            </p>

            <a href="/contact" className="learn-more-link">
              <span className="mx-1">Check the platform</span>
              <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </a>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Services;