import React, { useState } from 'react';

function Policy() {
  const [policies, setPolicies] = useState([]);
  const [newPolicy, setNewPolicy] = useState({
    name: '',
    description: '',
    maxSpeed: 0,
    maxFuelConsumption: 0,
    minBatteryLevel: 0,
    role: '',
    benefits: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPolicy({
      ...newPolicy,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPolicies([...policies, newPolicy]);
    setNewPolicy({
      name: '',
      description: '',
      maxSpeed: 0,
      maxFuelConsumption: 0,
      minBatteryLevel: 0,
      role: '',
      benefits: '',
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Vehicle Policy Management</h1>
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name of the Policy
          </label>
          <input
            type="text"
            name="name"
            value={newPolicy.name}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            name="description"
            value={newPolicy.description}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="maxSpeed">
            Maximum Allowed Speed (km/h)
          </label>
          <input
            type="number"
            name="maxSpeed"
            value={newPolicy.maxSpeed}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="maxFuelConsumption">
            Maximum Fuel Consumption (L/100km)
          </label>
          <input
            type="number"
            name="maxFuelConsumption"
            value={newPolicy.maxFuelConsumption}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="minBatteryLevel">
            Battery minimum level (%)
          </label>
          <input
            type="number"
            name="minBatteryLevel"
            value={newPolicy.minBatteryLevel}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
            Employee Role
          </label>
          <input
            type="text"
            name="role"
            value={newPolicy.role}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="benefits">
            Benefits
          </label>
          <input
            type="text"
            name="benefits"
            value={newPolicy.benefits}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Build Policy
          </button>
        </div>
      </form>
      <div>
        <h2 className="text-2xl font-bold mb-4">Company Policy</h2>
        {policies.length === 0 ? (
          <p className="text-gray-600">0 policy created yet.</p>
        ) : (
          <ul className="list-disc pl-5">
            {policies.map((policy, index) => (
              <li key={index} className="mb-2">
                <strong>{policy.name}</strong>: {policy.description} (Max Velocity: {policy.maxSpeed} km/h, Max Consumption: {policy.maxFuelConsumption} L/100km, Min Battery: {policy.minBatteryLevel}%, Role: {policy.role}, Benefits: {policy.benefits})
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Policy;