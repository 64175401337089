import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Vehicles.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';

const Vehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  useEffect(() => {
    axios.get('/api/vehicles')
      .then(response => {
        setVehicles(response.data);
      })
      .catch(error => {
        console.error('Error fetching vehicles:', error);
      });
  }, []);

  const handleViewDetails = (id) => {
    navigate(`/dashboard/vehicles/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/vehicles/edit/${id}`);
  };

  const handleView3D = (id) => {
    navigate(`/dashboard/vehicles/3d/${id}`);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      axios.post('/api/upload_csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log('File uploaded successfully');
        // Refresh the vehicles list
        axios.get('/api/vehicles')
          .then(response => {
            setVehicles(response.data);
          })
          .catch(error => {
            console.error('Error fetching vehicles:', error);
          });
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      });
    }
  };

  return (
    <div className="vehicles-container">
      <header className="vehicles-header">
        <h1>Registered Vehicle Management Dashboard</h1>
        <p>Manage your fleet of vehicles, view details, and analyze data through the digital twin representation.</p>
      </header>
      <div className="actions-container">
        <Link to="/dashboard/registervehicle" className="button primary">
          Register New Vehicle
        </Link>
        <div className="file-upload">
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleFileUpload} className="button secondary">
            Upload CSV
          </button>
        </div>
      </div>
      <table className="vehicles-table">
        <thead>
          <tr>
            <th hidden>ID</th>
            <th>Name</th>
            <th>Max Load Capacity (t)</th>
            <th>Fuel Tank Capacity (L)</th>
            <th>Fuel Consumption Full Load (L/100km)</th>
            <th>Fuel Consumption Empty Load (L/100km)</th>
            <th>Declared Emissions (g/km)</th>
            <th>Model Path</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map(vehicle => (
            <tr key={vehicle.id}>
              <td hidden>{vehicle.id}</td>
              <td>{vehicle.nome}</td>
              <td>{vehicle.carico_massimo_trasportabile}</td>
              <td>{vehicle.fuel_tank_capacity}</td>
              <td>{vehicle.consumo_a_pieno_carico}</td>
              <td>{vehicle.consumo_a_vuoto}</td>
              <td>{vehicle.emissioni_dichiarate}</td>
              <td>{vehicle.model_path}</td>
              <td>
                <button
                  onClick={() => handleViewDetails(vehicle.id)}
                  className="button primary"
                >
                  View Details
                </button>
                <button
                  onClick={() => handleEdit(vehicle.id)}
                  className="button secondary"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleView3D(vehicle.id)}
                  className="button primary"
                >
                  Digital Twin
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Vehicles;
