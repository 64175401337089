import React from 'react';
import { createRoot } from 'react-dom/client'; // Modifica l'importazione di createRoot
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const root = createRoot(document.getElementById('root')); // Usa createRoot da react-dom/client

root.render(
  <Router>
    <App />
  </Router>
);
