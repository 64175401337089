import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './VehicleForm.css'; // Assuming you create a CSS file for styling

const VehicleForm = () => {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState({
    nome: '',
    carico_massimo_trasportabile: '',
    fuel_tank_capacity: '',
    consumo_a_pieno_carico: '',
    consumo_a_vuoto: '',
    emissioni_dichiarate: '',
    model_path: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      axios.get(`/api/vehicles/${id}`)
        .then(response => {
          setVehicle(response.data);
        })
        .catch(error => {
          console.error('Error fetching vehicle:', error);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVehicle(prevVehicle => ({
      ...prevVehicle,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = id ? `/api/update_vehicle/${id}` : '/api/add_vehicle';
    const method = id ? 'put' : 'post';

    axios({
      method,
      url,
      data: vehicle
    })
    .then(response => {
      navigate('/dashboard/vehicles');
    })
    .catch(error => {
      console.error('Error saving vehicle:', error);
    });
  };

  return (
    <div className="vehicle-form-container">
      <h2>{id ? 'Edit Vehicle' : 'Add New Vehicle'}</h2>
      <form onSubmit={handleSubmit} className="vehicle-form">
        <label>
          Name:
          <input
            type="text"
            name="nome"
            value={vehicle.nome}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Maximum Load Capacity (tonnes):
          <input
            type="number"
            name="carico_massimo_trasportabile"
            value={vehicle.carico_massimo_trasportabile}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Fuel Tank Capacity (litres):
          <input
            type="number"
            name="fuel_tank_capacity"
            value={vehicle.fuel_tank_capacity}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Fuel Consumption at Full Load (l/100km):
          <input
            type="number"
            name="consumo_a_pieno_carico"
            value={vehicle.consumo_a_pieno_carico}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Fuel Consumption at Empty Load (l/100km):
          <input
            type="number"
            name="consumo_a_vuoto"
            value={vehicle.consumo_a_vuoto}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Declared Emissions (g/km):
          <input
            type="number"
            name="emissioni_dichiarate"
            value={vehicle.emissioni_dichiarate}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          URL 3D:
          <input
            type="text"
            name="model_path"
            value={vehicle.model_path}
            onChange={handleChange}
          />
        </label>
        <button type="submit" className="submit-button">
          {id ? 'Update Vehicle' : 'Add Vehicle'}
        </button>
      </form>
    </div>
  );
};

export default VehicleForm;
