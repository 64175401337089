import React, { useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { OBJLoader } from 'three-stdlib';
import * as THREE from 'three';
import chroma from 'chroma-js';

function TruckModel({ modelPath, km, setHoveredWheel, setTooltipPosition }) {
  const obj = useLoader(OBJLoader, modelPath);

  const getColorFromKm = (km) => {
    const scale = chroma.scale(['green', 'yellow', 'red']).domain([0, 10000, 20000]);
    return scale(km).hex();
  };

  useEffect(() => {
    const traverseAndSetColor = (object) => {
      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          let color;
          if (child.name === 'Truckdoor') {
            const doorColor = km.TruckDoor ? 'red' : 'green';
            child.material = new THREE.MeshStandardMaterial({ color: doorColor });
          } else {
            color = getColorFromKm(km[child.name]);
            child.material = new THREE.MeshStandardMaterial({ color });
          }
          child.userData = { name: child.name, km: km[child.name] };
          child.onPointerOver = (event) => {
            setHoveredWheel(child.userData);
            setTooltipPosition({ x: event.clientX, y: event.clientY });
          };
          child.onPointerOut = () => setHoveredWheel(null);
        }
      });
    };

    traverseAndSetColor(obj);
  }, [km, obj, setHoveredWheel, setTooltipPosition]);

  return <primitive object={obj} />;
}

export default TruckModel;
