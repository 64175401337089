import React, { useState, useEffect, Suspense, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import TruckModel from './TruckModel'; // Ensure this path is correct
import '../index.css'; // Ensure to import the CSS for styling

function Vehicle3D() {
  const { id: vehicleId } = useParams();
  const [modelPath, setModelPath] = useState('');
  const [km, setKm] = useState({ TrailerWheels: 0, TruckFrontWheels: 0, TruckRearWheels: 0 });
  const [hoveredWheel, setHoveredWheel] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        const response = await fetch(`/api/vehicles/${vehicleId}`);
        const data = await response.json();
        setModelPath(data.model_path); // Assume model_path is relative to the public folder
      } catch (error) {
        console.error('Error fetching vehicle details:', error);
      }
    };

    fetchVehicle();
  }, [vehicleId]);

  useEffect(() => {
    const fetchKilometers = async () => {
      try {
        const response = await fetch('/api/vehiclesdata');
        if (!response.ok) {
          throw new Error('Failed to fetch kilometers');
        }
        const data = await response.json();
        setKm(data);
      } catch (error) {
        console.error('Error fetching kilometers:', error);
      }
    };

    const interval = setInterval(fetchKilometers, 1000); // Fetch kilometers every second

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative', display: 'flex' }}>
      <Canvas shadows style={{ flex: 1 }}>
        <ambientLight intensity={0.3} />
        <directionalLight
          castShadow
          position={[5, 5, 5]}
          intensity={1.5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        <Suspense fallback={null}>
          {modelPath && <TruckModel modelPath={modelPath} km={km} setHoveredWheel={setHoveredWheel} setTooltipPosition={setTooltipPosition} />}
        </Suspense>
        <OrbitControls />
      </Canvas>
      <Sidebar km={km} />
    </div>
  );
}

function Sidebar({ km }) {
  const sidebarRef = useRef();

  useEffect(() => {
    const handleResize = (e) => {
      const sidebar = sidebarRef.current;
      sidebar.style.width = `${e.clientX}px`;
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleMouseDown = () => {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const resizer = sidebarRef.current.querySelector('.resizer');
    resizer.addEventListener('mousedown', handleMouseDown);

    return () => {
      resizer.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const formatTruckDoorStatus = (isOpen) => {
    return isOpen ? 'Aperto' : 'Chiuso';
  };

  return (
    <div ref={sidebarRef} className="sidebar">
      <div className="resizer" />
      <h3>Wheel Data</h3>
      <table>
        <thead>
          <tr>
            <th>Wheel</th>
            <th>Kilometers</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(km).map(([wheel, kmValue]) => (
            <tr key={wheel}>
              <td>{wheel}</td>
              <td>{wheel === 'TruckDoor' ? formatTruckDoorStatus(kmValue) : kmValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Vehicle3D;
