// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-vehicle-container {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .register-vehicle-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .register-vehicle-form label {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #555;
  }
  
  .register-vehicle-form input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 5px;
  }
  
  .submit-button {
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .success-message {
    color: green;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/RegisterVehicle.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":[".register-vehicle-container {\n    font-family: Arial, sans-serif;\n    max-width: 600px;\n    margin: 0 auto;\n    margin-top: 50px;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  }\n  \n  h2 {\n    text-align: center;\n    color: #333;\n    margin-bottom: 20px;\n  }\n  \n  .register-vehicle-form {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n  }\n  \n  .register-vehicle-form label {\n    display: flex;\n    flex-direction: column;\n    font-size: 16px;\n    color: #555;\n  }\n  \n  .register-vehicle-form input {\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    font-size: 16px;\n    margin-top: 5px;\n  }\n  \n  .submit-button {\n    padding: 12px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  .submit-button:hover {\n    background-color: #0056b3;\n  }\n  \n  .success-message {\n    color: green;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .error-message {\n    color: red;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
