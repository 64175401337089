import React from 'react';

const InfoItem = ({ icon, text }) => (
    <p className="flex items-start -mx-2">
        {icon}
        <span className="mx-2 text-gray-700 truncate w-72">{text}</span>
    </p>
);

const Contact = () => (
    <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-6">
            <div className="lg:flex lg:items-center lg:space-x-12">
                <div className="lg:w-1/2">
                    <h1 className="text-4xl font-bold text-gray-800 mb-8">
                        Contact Us for <br /> More Info
                    </h1>
                    <div className="mt-6 space-y-8 md:mt-8">
                        <InfoItem
                            icon={
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            }
                            text="Via Pietro Vivarelli, 10 Modena"
                        />
                        <InfoItem
                            icon={
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                            }
                            text="(+39) 331 4877702"
                        />
                        <InfoItem
                            icon={
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            }
                            text="saixtacc@gmail.com"
                        />
                    </div>
                </div>
                <div className="mt-8 lg:w-1/2 lg:mt-0 lg:mx-6">
                    <div className="w-full px-8 py-10 mx-auto bg-white rounded-lg shadow-lg">
                        <h1 className="text-2xl font-medium text-gray-700 mb-6 text-center">
                            We are currently working on this page.
                        </h1>
                        <p className="text-center text-gray-700">
                            For a more detailed demo, please contact us at
                            <a href="mailto:saixtacc@gmail.com" className="text-blue-500"> saixtacc@gmail.com</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default Contact;
