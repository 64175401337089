import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Trailers.css'; // Import CSS file for styling
import { Link } from 'react-router-dom';

const Trailers = () => {
  const [trailers, setTrailers] = useState([]);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  useEffect(() => {
    axios.get('/api/trailers')
      .then(response => {
        setTrailers(response.data);
      })
      .catch(error => {
        console.error('Error fetching trailers:', error);
      });
  }, []);

  const handleViewDetails = (id) => {
    navigate(`/dashboard/trailers/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/trailers/edit/${id}`);
  };

  const handleView3D = (id) => {
    navigate(`/dashboard/trailers/3d/${id}`);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      axios.post('/api/upload_csv_trailers', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log('File uploaded successfully');
        // Refresh the trailers list
        axios.get('/api/trailers')
          .then(response => {
            setTrailers(response.data);
          })
          .catch(error => {
            console.error('Error fetching trailers:', error);
          });
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      });
    }
  };

  return (
    <div className="trailers-container">
      <header className="trailers-header">
        <h1>Registered Trailers Management</h1>
        <p>Manage your fleet of trailers, view details, and perform actions.</p>
      </header>
      <div className="actions-container">
        <Link to="/dashboard/registertrailer" className="button primary">
          Register New Trailer
        </Link>
        <div className="file-upload">
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleFileUpload} className="button secondary">
            Upload CSV
          </button>
        </div>
      </div>
      <table className="trailers-table">
        <thead>
          <tr>
            <th hidden>ID</th>
            <th>Name</th>
            <th>Max Load Capacity (t)</th>
            <th>Weight (t)</th>
            <th>Available</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {trailers.map(trailer => (
            <tr key={trailer.id}>
              <td hidden>{trailer.id}</td>
              <td>{trailer.nome}</td>
              <td>{trailer.carico}</td>
              <td>{trailer.peso}</td>
              <td>{trailer.disponibile ? 'Yes' : 'No'}</td>
              <td>
                <button
                  onClick={() => handleViewDetails(trailer.id)}
                  className="button primary"
                >
                  View Details
                </button>
                <button
                  onClick={() => handleEdit(trailer.id)}
                  className="button secondary"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Trailers;
