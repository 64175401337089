import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Gestionale.css';  // Assicurati di aggiornare anche il CSS
import { API_BASE_URL } from './Config';

const Gestionale = ({ logout }) => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [editingRow, setEditingRow] = useState(null); // Stato per la riga in modifica
  const [editData, setEditData] = useState({}); // Dati di modifica temporanei
  const [searchTerm, setSearchTerm] = useState(''); // Stato per il filtro dell'azienda
  const [dateFilter, setDateFilter] = useState(''); // Stato per il filtro della data
  const [newRowData, setNewRowData] = useState({}); // Stato per i dati della nuova riga
  const [showAddForm, setShowAddForm] = useState(false); // Stato per la visibilità del modulo di aggiunta

  // Fetch rows from the backend
  useEffect(() => {
    const fetchRows = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/rows`);
        setRows(response.data);
        setFilteredRows(response.data);
      } catch (error) {
        console.error('Error fetching rows:', error);
      }
    };
    fetchRows();
  }, []);

  useEffect(() => {
    filterRows();
  }, [searchTerm, dateFilter, rows]);

  // Filter rows based on search term and date filter
  const filterRows = () => {
    let filtered = rows;

    if (searchTerm) {
      filtered = filtered.filter(row =>
        row.azienda.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (dateFilter) {
      filtered = filtered.filter(row =>
        new Date(row.data).toISOString().slice(0, 10) === dateFilter
      );
    }

    setFilteredRows(filtered);
  };

  // Handle input change in edit mode
  const handleEditChange = (e, id) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle input change for new row
  const handleNewRowChange = (e) => {
    const { name, value } = e.target;
    setNewRowData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Start editing a row
  const startEditing = (row) => {
    setEditingRow(row.id);
    setEditData(row);
  };

  // Save the changes for a row
  const handleSave = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/rows/${editData.id}`, editData);
      setRows((prevRows) => prevRows.map((row) => (row.id === editData.id ? response.data : row)));
      setFilteredRows((prevRows) => prevRows.map((row) => (row.id === editData.id ? response.data : row)));
      setEditingRow(null);
      setEditData({});
    } catch (error) {
      console.error('Error updating row:', error);
    }
  };

  // Cancel editing
  const handleCancel = () => {
    setEditingRow(null);
    setEditData({});
  };

  // Remove a row
  const handleRemoveRow = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/rows/${id}`);
      setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== id));
    } catch (error) {
      console.error('Error removing row:', error);
    }
  };

  // Add a new row
  const handleAddRow = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/addrows`, newRowData);
      setRows((prevRows) => [...prevRows, { ...newRowData, id: response.data.id }]);
      setFilteredRows((prevRows) => [...prevRows, { ...newRowData, id: response.data.id }]);
      setNewRowData({});
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding row:', error);
    }
  };

  // Function to extract domain and path from URL
  const formatLink = (url) => {
    try {
      const { hostname, pathname } = new URL(url);
      return `${hostname}${pathname}`;
    } catch (error) {
      console.error('Error formatting link:', error);
      return url;
    }
  };

  const exportToCSV = () => {
    // Convert filteredRows to CSV
    const header = [
      'Azienda', 'Contattato da', 'Data', 'Risposta', 'Link azienda', 'Ambito', 'Note'
    ];
    const rows = filteredRows.map(row => [
      row.azienda, row.contattatoDa, new Date(row.data).toLocaleDateString(), row.risposta,
      row.linkAzienda, row.ambito, row.note
    ]);
  
    const csvContent = [
      header.join(','), // Header row
      ...rows.map(e => e.join(',')) // Data rows
    ].join('\n');
  
    // Create a downloadable link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Create a link to download the CSV file
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'dati.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Function to truncate text
  const truncateText = (text, maxLength = 100) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Gestionale</h1>

      {/* Filter controls */}
      <div className="mb-4">
        <div className="form-group">
          <label htmlFor="searchTerm">Cerca per Azienda:</label>
          <input
            type="text"
            id="searchTerm"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="dateFilter">Filtra per Data:</label>
          <input
            type="date"
            id="dateFilter"
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            className="form-control"
          />
        </div>
      </div>

      {/* Add new row button */}
      <div className="mb-4 text-center">
        <button
          className="btn btn-primary"
          onClick={() => setShowAddForm(!showAddForm)}
        >
          {showAddForm ? 'Annulla' : 'Aggiungi Riga'}
        </button>
        <button
          className="btn btn-info ml-2"
          onClick={exportToCSV}
        >
          Esporta CSV
        </button>
      </div>

      {/* Add new row form */}
      {showAddForm && (
        <div className="mb-4">
          <h3>Aggiungi Nuova Riga</h3>
          <div className="form-group">
            <label htmlFor="azienda">Azienda:</label>
            <input
              type="text"
              id="azienda"
              name="azienda"
              value={newRowData.azienda || ''}
              onChange={handleNewRowChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="contattatoDa">Contattato da:</label>
            <input
              type="text"
              id="contattatoDa"
              name="contattatoDa"
              value={newRowData.contattatoDa || ''}
              onChange={handleNewRowChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="data">Data:</label>
            <input
              type="date"
              id="data"
              name="data"
              value={newRowData.data || ''}
              onChange={handleNewRowChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="risposta">Risposta:</label>
            <input
              type="text"
              id="risposta"
              name="risposta"
              value={newRowData.risposta || ''}
              onChange={handleNewRowChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="linkAzienda">Link Azienda:</label>
            <input
              type="text"
              id="linkAzienda"
              name="linkAzienda"
              value={newRowData.linkAzienda || ''}
              onChange={handleNewRowChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="ambito">Ambito:</label>
            <input
              type="text"
              id="ambito"
              name="ambito"
              value={newRowData.ambito || ''}
              onChange={handleNewRowChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="note">Note:</label>
            <input
              type="text"
              id="note"
              name="note"
              value={newRowData.note || ''}
              onChange={handleNewRowChange}
              className="form-control"
            />
          </div>
          <div className="text-center">
            <button
              className="btn btn-success"
              onClick={handleAddRow}
            >
              Aggiungi
            </button>
          </div>
        </div>
      )}

      <div className="table-responsive">
        <table className="table table-striped table-bordered text-center">
          <thead className="thead-dark">
            <tr>
              <th>#</th> {/* Column for row count */}
              <th>Azienda</th>
              <th>Contattato da</th>
              <th>Data</th>
              <th>Risposta</th>
              <th>Link azienda</th>
              <th>Ambito</th> {/* New Column */}
              <th>Note</th> {/* New Column */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRows.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td> {/* Display row count */}
                <td>
                  {editingRow === row.id ? (
                    <input
                      type="text"
                      name="azienda"
                      value={editData.azienda}
                      onChange={(e) => handleEditChange(e, row.id)}
                      className="form-control"
                    />
                  ) : (
                    row.azienda
                  )}
                </td>
                <td>
                  {editingRow === row.id ? (
                    <input
                      type="text"
                      name="contattatoDa"
                      value={editData.contattatoDa}
                      onChange={(e) => handleEditChange(e, row.id)}
                      className="form-control"
                    />
                  ) : (
                    row.contattatoDa
                  )}
                </td>
                <td>
                  {editingRow === row.id ? (
                    <input
                      type="date"
                      name="data"
                      value={editData.data}
                      onChange={(e) => handleEditChange(e, row.id)}
                      className="form-control"
                    />
                  ) : (
                    new Date(row.data).toLocaleDateString()
                  )}
                </td>
                <td>
                  {editingRow === row.id ? (
                    <input
                      type="text"
                      name="risposta"
                      value={editData.risposta}
                      onChange={(e) => handleEditChange(e, row.id)}
                      className="form-control"
                    />
                  ) : (
                    <>
                      <div className="response-text">
                        {truncateText(row.risposta)}
                      </div>
                      {row.risposta.length > 100 && (
                        <button
                          className="btn btn-link"
                          onClick={() => alert(row.risposta)}
                        >
                          Leggi di più
                        </button>
                      )}
                    </>
                  )}
                </td>
                <td>
                  {editingRow === row.id ? (
                    <input
                      type="text"
                      name="linkAzienda"
                      value={editData.linkAzienda}
                      onChange={(e) => handleEditChange(e, row.id)}
                      className="form-control"
                    />
                  ) : (
                    <a href={row.linkAzienda} target="_blank" rel="noopener noreferrer">
                      {formatLink(row.linkAzienda)}
                    </a>
                  )}
                </td>
                <td>
                  {editingRow === row.id ? (
                    <input
                      type="text"
                      name="ambito"
                      value={editData.ambito || ''}
                      onChange={(e) => handleEditChange(e, row.id)}
                      className="form-control"
                    />
                  ) : (
                    row.ambito || ''
                  )}
                </td>
                <td>
                  {editingRow === row.id ? (
                    <input
                      type="text"
                      name="note"
                      value={editData.note || ''}
                      onChange={(e) => handleEditChange(e, row.id)}
                      className="form-control"
                    />
                  ) : (
                    row.note || ''
                  )}
                </td>
                <td>
                  {editingRow === row.id ? (
                    <>
                      <button className="btn btn-success btn-sm" onClick={handleSave}>Save</button>
                      <button className="btn btn-secondary btn-sm ml-2" onClick={handleCancel}>Cancel</button>
                    </>
                  ) : (
                    <>
                      <button className="btn btn-warning btn-sm" onClick={() => startEditing(row)}>Edit</button>
                      <button className="btn btn-danger btn-sm ml-2" onClick={() => handleRemoveRow(row.id)}>Remove</button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Gestionale;
