// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Gestionale.css */
.table td input {
    border: none;
    background-color: #f8f9fa;
  }
  
  .table td input:focus {
    outline: none;
    box-shadow: none;
  }
  
  .table td a {
    text-decoration: none;
    color: #007bff;
  }
  
  .table td a:hover {
    text-decoration: underline;
  }
  
  .response-text {
    max-width: 200px; /* Imposta una larghezza massima per il testo troncato */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/Gestionale.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,gBAAgB,EAAE,wDAAwD;IAC1E,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;EACrB","sourcesContent":["/* Gestionale.css */\n.table td input {\n    border: none;\n    background-color: #f8f9fa;\n  }\n  \n  .table td input:focus {\n    outline: none;\n    box-shadow: none;\n  }\n  \n  .table td a {\n    text-decoration: none;\n    color: #007bff;\n  }\n  \n  .table td a:hover {\n    text-decoration: underline;\n  }\n  \n  .response-text {\n    max-width: 200px; /* Imposta una larghezza massima per il testo troncato */\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
