import { istitution, torta, computer, report, razzo } from "../assets";


export const features1 = [
  {
    id: "feature-4",
    icon: razzo,
    title: "Monitor a Digital Twin of your vehicle",
  },
  {
    id: "feature-1",
    icon: torta,
    title: "Exploit a powerful data analysis tool",
  },
  {
    id: "feature-2",
    icon: computer,
    title: "Check on a personalized dashboard for each vehicle",
  },
  {
    id: "feature-3",
    icon: report,
    title: "Extract report on emission, based on real data",
  },
  
  {
    id: "feature-6",
    icon: istitution,
    title: "We are a student startup, full of motivation",
  }
  
];

