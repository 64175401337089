import React, { useState, useEffect } from 'react';

function Employees() {
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newEmployee, setNewEmployee] = useState({ name: '', role: '', email: '' });
  const [showAddEmployee, setShowAddEmployee] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch('/api/employees');
        const data = await response.json();
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee({
      ...newEmployee,
      [name]: value,
    });
  };

  const handleAddEmployee = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/employees', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEmployee),
      });
      if (response.ok) {
        const addedEmployee = await response.json();
        setEmployees([...employees, addedEmployee]);
        setNewEmployee({ name: '', role: '', email: '' });
        setShowAddEmployee(false); // Nascondi il modulo dopo aver aggiunto il dipendente
      } else {
        console.error('Failed to add employee');
      }
    } catch (error) {
      console.error('Error adding employee:', error);
    }
  };

  const filteredEmployees = employees.filter(employee => {
    const matchesName = employee.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesRole = selectedRole === '' || employee.role === selectedRole;
    return matchesName && matchesRole;
  });

  const uniqueRoles = [...new Set(employees.map(employee => employee.role))];

  return (
    <div className="flex">
      <div className="w-1/4 bg-gray-100 p-4">
        <h2 className="text-xl font-semibold mb-4">Aggiungi Nuovo Dipendente</h2>
        <form onSubmit={handleAddEmployee} className="mb-6">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Nome
            </label>
            <input
              type="text"
              name="name"
              value={newEmployee.name}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
              Ruolo
            </label>
            <input
              type="text"
              name="role"
              value={newEmployee.role}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={newEmployee.email}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Aggiungi Dipendente
            </button>
          </div>
        </form>
      </div>

      <div className="container mx-auto px-4 py-8 w-3/4">
        <h1 className="text-3xl font-bold mb-6">Gestione dei Dipendenti</h1>

        <div className="mb-6">
          <input
            type="text"
            placeholder="Cerca dipendenti..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          />
          <select
            value={selectedRole}
            onChange={handleRoleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">Tutti i ruoli</option>
            {uniqueRoles.map(role => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </select>
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <ul className="divide-y divide-gray-200">
            {filteredEmployees.map((employee) => (
              <li key={employee.id} className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="text-lg font-medium text-gray-900">{employee.name}</div>
                  <div className="mt-2 flex space-x-2">
                    <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                      {employee.role}
                    </span>
                    <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {employee.email}
                    </span>
                  </div>
                </div>
                {employee.vehicle && (
                  <div className="mt-4">
                    <h3 className="text-sm font-medium text-gray-700">Veicolo Associato:</h3>
                    <div className="text-sm text-gray-900">{employee.vehicle.name}</div>
                    <div className="text-sm text-gray-500">{employee.vehicle.description}</div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Employees;
