import React, { useState, useEffect, Suspense, useRef } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { OBJLoader } from 'three-stdlib';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import chroma from 'chroma-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes, faBan  } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import '../index.css'; // Assicurati di importare il CSS per lo stile

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function TruckModel({ km, setHoveredWheel, setTooltipPosition, showOutline, showOnlyTruckDoor, showOnlyWheels }) {
  const obj = useLoader(OBJLoader, '/Truck.obj'); // Supponendo che il file truck.obj sia nella directory pubblica

  const getColorFromKm = (km, name) => {
    let domain;
  
    // Check if name starts with 'light_ban'
    if (name && name.startsWith('light_ban')) {
      domain = [0, 25000, 50000];
    } else {
      domain = [0, 5000, 10000, 20000];
    }
  
    const scale = chroma.scale(['green', 'orange', 'red']).domain(domain);
    return scale(km).hex();
  };
  
  

  useEffect(() => {
    // Funzione per attraversare l'oggetto e trovare le ruote
    const traverseAndSetColor = (object) => {
      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          // Imposta il colore delle parti in base ai chilometri
          const color = getColorFromKm(km[child.name], child.name);
          child.material = new THREE.MeshStandardMaterial({ color });

          child.userData = { name: child.name, km: km[child.name] };
          child.onPointerOver = (event) => {
            setHoveredWheel(child.userData);
            setTooltipPosition({ x: event.clientX, y: event.clientY });
          };
          child.onPointerOut = () => setHoveredWheel(null);
        }
      });
    };

    // Chiama la funzione traverseAndSetColor sull'oggetto caricato
    traverseAndSetColor(obj);
  }, [km, obj, setHoveredWheel, setTooltipPosition]);

  useEffect(() => {
    // Funzione per applicare la geometria degli spigoli
    const applyOutline = (object) => {
      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          if (showOutline) {
            // Rendi il camion trasparente dall'interno
            child.material.side = THREE.BackSide; // Rendi visibile solo il lato esterno
            child.material.transparent = true;
            child.material.opacity = 0.3; // Opacità bassa per mostrare il contorno

            // Crea il contorno solo quando showOutline è true
            const edges = new THREE.EdgesGeometry(child.geometry);
            const line = new THREE.LineSegments(
              edges,
              new THREE.LineBasicMaterial({ color: 0xffffff, transparent: true, opacity: 1 })
            );
            line.name = 'outline';
            child.add(line);
          } else {
            // Rimuovi il contorno e ripristina il camion
            const outline = child.getObjectByName('outline');
            if (outline) {
              child.remove(outline);
            }
            child.material.transparent = false;
            child.material.opacity = 1;
            child.material.side = THREE.FrontSide; // Rendi visibile tutto il camion
          }
        }
      });
    };

    // Applica o rimuovi il contorno in base allo stato showOutline
    applyOutline(obj);
  }, [obj, showOutline]);

  useEffect(() => {
    // Funzione per gestire la visibilità dei children
    const toggleVisibility = (object) => {
      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          if (showOnlyTruckDoor) {
            child.visible = child.name === 'TruckDoor' || child.name.startsWith('light_ban');
          } else {
            child.visible = true;
          }
        }
      });
    };

    // Chiama la funzione toggleVisibility sull'oggetto caricato
    toggleVisibility(obj);
  }, [obj, showOnlyTruckDoor]);

  useEffect(() => {
    // Funzione per gestire la visibilità dei children
    const toggleVisibility1 = (object) => {
      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          if (showOnlyWheels) {
            child.visible = child.name.endsWith('Wheels');
          } else {
            child.visible = true;
          }
        }
      });
    };

    // Chiama la funzione toggleVisibility sull'oggetto caricato
    toggleVisibility1(obj);
  }, [obj, showOnlyWheels]);

  return <primitive object={obj} />;
}

function Truck3d() {
  const [km, setKm] = useState({ TrailerWheels: 0, TruckFrontWheels: 0, TruckRearWheels: 0 });
  const [hoveredWheel, setHoveredWheel] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showOutline, setShowOutline] = useState(false);
  const [showOnlyTruckDoor, setShowOnlyTruckDoor] = useState(false);
  const [showOnlyWheels, setShowOnlyWheels] = useState(false);
  const [showWearChart, setShowWearChart] = useState(false);
  const [showBrakeChart, setShowBrakeChart] = useState(false);

  useEffect(() => {
    const fetchKilometers = async () => {
      try {
        const response = await fetch('/api/vehiclesdata');
        if (!response.ok) {
          throw new Error('Failed to fetch kilometers');
        }
        const data = await response.json();
        setKm(data);
      } catch (error) {
        console.error('Error fetching kilometers:', error);
      }
    };

    const interval = setInterval(fetchKilometers, 1000); // Chiama fetchKilometers ogni secondo

    return () => clearInterval(interval); // Cancella l'intervallo quando il componente viene smontato
  }, []);

  useEffect(() => {
    const chartContainer = document.getElementById('chartContainer');
    if (!chartContainer) return;
    const resizer = chartContainer.querySelector('.resizer');
    let isResizing = false;
    let isDragging = false;
    let lastX = 0;
    let lastY = 0;

    const onMouseMove = (e) => {
      if (isResizing) {
        chartContainer.style.width = `${e.clientX - chartContainer.offsetLeft}px`;
        chartContainer.style.height = `${e.clientY - chartContainer.offsetTop}px`;
      } else if (isDragging) {
        const dx = e.clientX - lastX;
        const dy = e.clientY - lastY;
        chartContainer.style.left = `${chartContainer.offsetLeft + dx}px`;
        chartContainer.style.top = `${chartContainer.offsetTop + dy}px`;
        lastX = e.clientX;
        lastY = e.clientY;
      }
    };

    const onMouseUp = () => {
      isResizing = false;
      isDragging = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDownResizer = (e) => {
      isResizing = true;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    const onMouseDownContainer = (e) => {
      if (e.target === resizer) return;
      isDragging = true;
      lastX = e.clientX;
      lastY = e.clientY;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    resizer.addEventListener('mousedown', onMouseDownResizer);
    chartContainer.addEventListener('mousedown', onMouseDownContainer);

    return () => {
      resizer.removeEventListener('mousedown', onMouseDownResizer);
      chartContainer.removeEventListener('mousedown', onMouseDownContainer);
    };
  }, [showWearChart, showBrakeChart]);

  const filterComponents = (name) => {
    if (showOnlyTruckDoor) return name === 'TruckDoor' || name.startsWith('light_ban');
    if (showOnlyWheels) return name.endsWith('Wheels');
    return true;
  };

  // Static data for wear chart
  const wearData = {
    labels: Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`),
    datasets: [
      {
        label: 'Wear Over Last Month',
        data: [12, 19, 3, 5, 2, 3, 7, 10, 9, 8, 7, 6, 5, 4, 8, 10, 12, 14, 16, 18, 20, 18, 16, 14, 12, 10, 8, 6, 4, 2],
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
      },
    ],
  };

  // Static data for brake chart
  const brakeData = {
    labels: Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`),
    datasets: [
      {
        label: 'Sudden Braking Incidents',
        data: [2, 3, 2, 4, 3, 2, 1, 2, 3, 4, 5, 4, 3, 2, 1, 2, 3, 4, 5, 4, 3, 2, 1, 2, 3, 4, 5, 6, 5, 4],
        borderColor: 'rgba(255,99,132,1)',
        backgroundColor: 'rgba(255,99,132,0.2)',
        fill: true,
      },
      {
        label: 'Load Stress',
        data: [20, 30, 25, 28, 32, 35, 30, 25, 20, 22, 18, 16, 14, 20, 22, 24, 26, 28, 30, 32, 34, 36, 32, 28, 24, 20, 18, 16, 14, 12],
        borderColor: 'rgba(54,162,235,1)',
        backgroundColor: 'rgba(54,162,235,0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Trend Over the Last Month',
      },
    },
  };

  const getMaxWearDay = () => {
    const wearValues = wearData.datasets[0].data;
    const maxWear = Math.max(...wearValues);
    const maxWearDay = wearValues.indexOf(maxWear) + 1;
    return `Day with more brake system activations: Day ${maxWearDay} with ${maxWear} units of wear`;
  };

  const getMaxBrakeDay = () => {
    const brakeValues = brakeData.datasets[0].data;
    const maxBrake = Math.max(...brakeValues);
    const maxBrakeDay = brakeValues.indexOf(maxBrake) + 1;
    return `Day with the highest tire wear: Day ${maxBrakeDay} with ${maxBrake} sudden braking incidents`;
  };

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative', display: 'flex' }}>
      <Canvas shadows style={{ flex: 1 }}
      camera={{ position: [0, 5, 20], fov: 50 }} // Imposta la posizione iniziale della telecamera 
      >
        <ambientLight intensity={0.3} />
        <directionalLight
          castShadow
          position={[5, 5, 5]}
          intensity={1.5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        <Suspense fallback={null}>
          <TruckModel
            km={km}
            setHoveredWheel={setHoveredWheel}
            setTooltipPosition={setTooltipPosition}
            showOutline={showOutline}
            showOnlyTruckDoor={showOnlyTruckDoor}
            showOnlyWheels={showOnlyWheels}
          />
        </Suspense>
        <OrbitControls />
      </Canvas>
      <Sidebar
        km={km}
        setShowOutline={setShowOutline}
        setShowOnlyTruckDoor={setShowOnlyTruckDoor}
        setShowOnlyWheels={setShowOnlyWheels}
        setShowWearChart={setShowWearChart}
        setShowBrakeChart={setShowBrakeChart}
      />
      {showWearChart && (
        <div className="chart-container" id="chartContainer">
          <button className="close-button" onClick={() => setShowWearChart(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <Line data={wearData} options={{ ...options, title: { text: 'Wear Trend Over the Last Month' } }} />
          <p>{getMaxWearDay()}</p>
          <div className="resizer" />
        </div>
      )}
      {showBrakeChart && (
        <div className="chart-container" id="chartContainer">
          <button className="close-button" onClick={() => setShowBrakeChart(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <Line data={brakeData} options={{ ...options, title: { text: 'Brake Analysis Over the Last Month' } }} />
          <p>{getMaxBrakeDay()}</p>
          <div className="resizer" />
        </div>
      )}
    </div>
  );
}

function Sidebar({ km, setShowOutline, setShowOnlyTruckDoor, setShowOnlyWheels, setShowWearChart, setShowBrakeChart }) {
  const sidebarRef = useRef();

  const componentDisplayNames = {
    TrailerWheels: 'Trailer Wheels',
    TruckFrontWheels: 'Truck Front Wheels',
    TruckRearWheels: 'Truck Rear Wheels',
    Wheels: 'Back-Left Wheel',
    cerobong_asp: 'Exhaust Pipe',
    light_ban: 'Front Brakes',
    light_ban_2: 'Trailer Brakes',
    light_ban_3: 'Back-Front Brakes',
    light_ban_4: 'Back-Trailer Brakes',
    'light_ban_4.001': 'Back-Back Brakes'
  };

  useEffect(() => {
    const handleResize = (e) => {
      const sidebar = sidebarRef.current;
      const newWidth = window.innerWidth - e.clientX;
      sidebar.style.width = `${newWidth}px`;
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleMouseDown = () => {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const resizer = sidebarRef.current.querySelector('.resizer');
    resizer.addEventListener('mousedown', handleMouseDown);

    return () => {
      resizer.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const formatTruckDoorStatus = (isOpen) => {
    return isOpen ? 'Aperto' : 'Chiuso';
  };

  const renderWarningIcon = (kmValue, threshold, divietoThreshold) => {
    const warnings = [];
    if (kmValue > threshold) {
      warnings.push(
        <span className="warning-icon-container" key="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} className="warning-icon" />
          <span className="tooltip">Attention: {kmValue} km exceeds the threshold of {threshold} km!</span>
        </span>
      );
    }
    if (kmValue > divietoThreshold) {
      warnings.push(
        <span className="warning-icon-container" key="divieto">
          <FontAwesomeIcon icon={faBan} className="ban-icon" />
          <span className="tooltip">FORBIDDEN: {kmValue} km exceeds the limit of {divietoThreshold} km!</span>
        </span>
      );
    }
    return warnings;
  };

  return (
    <div ref={sidebarRef} className="sidebar">
      <div className="resizer" />
      <h1>Digital Twin Components</h1>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Component</th>
              <th>Kilometers</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(km).map(([component, kmValue]) => (
              <tr key={component}>
                <td>{componentDisplayNames[component] || component}</td>
                <td>{component === 'TruckDoor' ? formatTruckDoorStatus(kmValue) : kmValue}</td>
                <td>
                  {component.includes('Wheels') && renderWarningIcon(kmValue, 15000, 25000)}
                  {component.includes('light_ban') && renderWarningIcon(kmValue, 25000, 50000)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="buttons">
        <button onClick={() => { setShowOnlyWheels(prev => !prev); setShowWearChart(true); }}>Wheels System Analysis</button>
        <button onClick={() => { setShowOnlyTruckDoor(prev => !prev); setShowBrakeChart(true); }}>Braking System Analysis</button>
        <button onClick={() => alert('Open Door feature coming soon')}>Open Door</button>
        <button onClick={() => alert('Close Door feature coming soon')}>Close Door</button>
        <button onClick={() => alert('Reset Kilometers feature coming soon')}>Reset Kilometers</button>
        <button onClick={() => alert('Calibrate Sensors feature coming soon')}>Calibrate Sensors</button>
        <button onClick={() => alert('Turn On Lights feature coming soon')}>Turn On Lights</button>
        <button onClick={() => alert('Turn Off Lights feature coming soon')}>Turn Off Lights</button>
        <button onClick={() => alert('Honk Horn feature coming soon')}>Honk Horn</button>
        <button onClick={() => alert('Activate Suspension feature coming soon')}>Activate Suspension</button>
        <button onClick={() => alert('Deactivate Suspension feature coming soon')}>Deactivate Suspension</button>
        <button onClick={() => alert('Check Fuel Level feature coming soon')}>Check Fuel Level</button>
        <button onClick={() => alert('Start Engine feature coming soon')}>Start Engine</button>
        <button onClick={() => alert('Stop Engine feature coming soon')}>Stop Engine</button>
        <button onClick={() => alert('Open Front Left Window feature coming soon')}>Open Front Left Window</button>
        <button onClick={() => alert('Close Front Left Window feature coming soon')}>Close Front Left Window</button>
        <button onClick={() => alert('Schedule Maintenance feature coming soon')}>Schedule Maintenance</button>
        <button onClick={() => alert('Monitor Temperature feature coming soon')}>Monitor Temperature</button>
        <button onClick={() => alert('Track GPS feature coming soon')}>Track GPS</button>
      </div>
    </div>
  );
}


export default Truck3d;
