// import React, { useState } from 'react';
// import axios from 'axios';
// import './BestChoice.css'; // Assuming you create a CSS file for styling

// const BestChoice = () => {
//   const [vehicleDetails, setVehicleDetails] = useState(null);
//   const [km, setKm] = useState('');
//   const [carico, setCarico] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError('');
//     try {
//       const response = await axios.post('http://localhost:5000/bestchoice', { km, carico });
//       setVehicleDetails(response.data);
//     } catch (error) {
//       setError('Error fetching data, please try again later.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="best-choice-container">
//       <h1>Best Choice Vehicles</h1>
//       <p>Through this portal, you can choose the best vehicle for your route by considering emissions, fuel consumption, and cost. Simply input the distance (km) and the load (tonnes) to get the best recommendation.</p>
//       <div style={{ margin: "40px" }}>
//         <form onSubmit={handleSubmit} className="best-choice-form">
//           <label>
//             Km:
//             <input type="number" value={km} onChange={(e) => setKm(e.target.value)} />
//           </label>
//           <label>
//             Carico (tonnes):
//             <input type="number" value={carico} onChange={(e) => setCarico(e.target.value)} />
//           </label>
//           <button type="submit" disabled={loading}>
//             {loading ? 'Loading...' : 'Submit'}
//           </button>
//         </form>
//         {error && <p className="error-message">{error}</p>}
//         {vehicleDetails && (
//           <div className="results">
//             <h2>Recommended Vehicles</h2>
//             <p>{vehicleDetails.mezzi}</p>
//             <h3>Details:</h3>
//             <ul>
//               {vehicleDetails.details.map((detail, index) => (
//                 <li key={index}>{detail}</li>
//               ))}
//             </ul>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default BestChoice;
import React, { useState } from 'react';
import axios from 'axios';
import './BestChoice.css'; // Assuming you create a CSS file for styling

const BestChoice = () => {
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [carico, setCarico] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('http://localhost:5000/bestchoice', { start, end, carico });
      setVehicleDetails(response.data);
    } catch (error) {
      setError('Error fetching data, please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-choice-container">
      <h1>Best Choice Vehicles</h1>
      <p>Through this portal, you can choose the best vehicle among you database in order to complete your transportation, considering the route, the fuel consumption, and costs of each Vehicle. Simply input the starting and end point and the load to transport (tonnes) to get the best recommendation.</p>
      <div className="flex">
      <div className="best-choice-container">
      <p>Input data</p>
        <div style={{ margin: "40px" }}>
          <form onSubmit={handleSubmit} className="best-choice-form">
            <label>
              Start Position:
              <input type="text" value={start} onChange={(e) => setStart(e.target.value)} />
            </label>
            <label>
              Destination:
              <input type="text" value={end} onChange={(e) => setEnd(e.target.value)} />
            </label>
            <label>
              Carico (tonnes):
              <input type="number" value={carico} onChange={(e) => setCarico(e.target.value)} />
            </label>
            <button type="submit" disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
          </form>
          {error && <p className="error-message">{error}</p>}

        </div>
      </div>
      <div className="best-choice-container">
        <p>Recommendations taken from our algorithm</p>
        <div style={{ margin: "40px" }}>
          
          {error && <p className="error-message">{error}</p>}
          {vehicleDetails && (
            <div className="results">
              <h2>Recommended Vehicle</h2>
              <p>{vehicleDetails.mezzi}</p>
              <h3>Minimum Savings</h3>
              <p>{vehicleDetails.risparmio}</p>
              <h2>Possible Improvement</h2>
              <p>{vehicleDetails.miglioramento}</p>
            </div>
          )}
        </div>
      </div>
      </div>
    </div>
  );
};

export default BestChoice;
