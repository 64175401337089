import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Assicurati di avere un logo nella cartella assets

function Footer() {
  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        <div style={logoSectionStyle}>
          <img src={logo} alt="Company Logo" style={logoStyle} />
        </div>
        <div style={linksSectionStyle}>
          <h3 style={sectionTitleStyle}>Company</h3>
          <Link to="/services" style={linkStyle}>About us</Link>
        </div>
        <div style={contactSectionStyle}>
          <h3 style={sectionTitleStyle}>Contact</h3>
          <Link to="/contact" style={linkStyle}>Contacts</Link>
          <p style={textStyle}>Email: saixtacc@gmail.com</p>
          <p style={textStyle}>Cell: +39 331 4877702</p>
          <p style={textStyle}>Address: Via Pietro Vivarelli, 10 Modena</p>
        </div>
      </div>
      <div style={bottomFooterStyle}>
        <p style={textStyle}>© 2024 SAIX. All rights reserved.</p>
      </div>
    </footer>
  );
}

const footerStyle = {
  backgroundColor: '#2179cb',
  color: '#fff',
  padding: '20px 0',
  marginTop: '40px',  // Imposta la distanza dal top
  fontFamily: 'Arial, sans-serif',
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '0 20px',
};

const logoSectionStyle = {
  flex: '1',
  minWidth: '200px',
  textAlign: 'center',
  marginBottom: '20px',
};

const linksSectionStyle = {
  flex: '1',
  minWidth: '200px',
  marginBottom: '20px',
  textAlign: 'center',
};

const contactSectionStyle = {
  flex: '1',
  minWidth: '200px',
  marginBottom: '20px',
  textAlign: 'center',
};

const logoStyle = {
  width: '120px',
  marginBottom: '10px',
};

const sectionTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '10px',
  textTransform: 'uppercase',
};

const textStyle = {
  color: '#fff',
  margin: '5px 0',
};

const linkStyle = {
  color: '#fff',
  textDecoration: 'none',
  display: 'block',
  marginBottom: '5px',
};

const bottomFooterStyle = {
  textAlign: 'center',
  padding: '10px 0',
  borderTop: '1px solid #444',
};

export default Footer;
